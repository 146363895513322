import React from 'react'
import { connect } from 'react-redux'
import Digitalization from './../digitalization'

function DigitalizationEn({ translations, lang }) {
    return (
        <Digitalization translations={translations} lang={lang}/>
    )
}

const mapStateToProps = state => ({
    translations: state.preferences.translations,
    lang: state.preferences.lang,
    serverCall: state.preferences.serverCall
})

export default connect(mapStateToProps)(DigitalizationEn)
